// Install from npm:
// 'npm i leaflet'
// https://leafletjs.com/
import L from 'leaflet';

export const leaflet = () => {
  const elm = document.getElementById('map');

  // 要素がない場合は処理を止める
  if (!elm) return;

  // 座標データ
  const lat = '43.78996188648606';
  const lng = '142.359129866673';

  const map = L.map('map', {
    dragging: false, // マウスドラッグによるパン操作を不可
    touchZoom: false, // タッチによるズーム操作を不可
    scrollWheelZoom: false, // スクロールによるズーム操作を不可
    doubleClickZoom: false, // ダブルクリックによるズーム操作を不可
    boxZoom: false, // [Shift] + ドラッグによるボックスズーム操作を不可
    tap: false, // タップによるズーム操作を不可
    keyboard: false, // キーボードによる操作を不可
    zoomControl: false, // ズーム コントロールの非表示
  }).setView([lat, lng], 15);

  // オリジナルマーカー
  const myIcon = L.icon({
    iconUrl: '/cms/wp-content/themes/omachi.sogawa-ganka/assets/images/marker.svg',
    iconSize: [56, 70], // マーカーサイズ
    iconAnchor: [28, 70], // マーカー座標基準点
  });
  L.marker([lat, lng], { icon: myIcon }).addTo(map);

  // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //   attribution:
  //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  // }).addTo(map);

  L.tileLayer('https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}', {
    attribution: `<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a> | <a href='http://maps.google.co.jp/maps?q=${lat},${lng}' target='_blank'>拡大地図を表示</a>`,
  }).addTo(map);
};
